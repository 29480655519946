<template>
  <div>

    <form-wizard
        color="#2d71b8"
        :title="null"
        :subtitle="null"
        layout="vertical"
        finish-button-text="Update"
        back-button-text="Previous"
        class="wizard-vertical mb-3"
        @on-complete="editModule"

    >
      <tab-content title="Edit Module Details" icon="feather icon-user-plus">
        <validation-observer ref="moduleDetailsRules" v-if="!finished">
          <b-form>
            <b-row>
              <b-col
                  cols="12"
                  class="mb-2"
              >
                <h5 class="mb-0">
                  Module Details
                </h5>
                <small class="text-muted">
                  Enter Detailed Information.
                </small>
              </b-col>
              <b-col md="6">

                <b-form-group
                    label="Title"
                    label-for="fh-title"

                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Title"
                  >
                    <b-input-group
                        class="input-group-merge"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserPlusIcon"/>
                      </b-input-group-prepend>
                      <b-form-input
                          id="fh-name"
                          placeholder="Karim"
                          v-model="modify.title"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </b-col>
              <b-col md="6">
                <b-form-group
                    label="Video Link"
                    label-for="fh-video_link"
                >

                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UserMinusIcon"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="fh-video_link"
                        placeholder="youto.be"
                        v-model="modify.video_link"
                    />
                  </b-input-group>

                </b-form-group>
              </b-col>

              <b-row>
                <b-col md="2"></b-col>
                <b-col md="8" id="Product image 2" class="mb-2">
                  <b-col class="d-flex">
                    <b-img thumbnail fluid :src="product_image" alt="Theme Thumbnail"/>
                    <!--              <b-button-->
                    <!--                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
                    <!--                  variant="outline-danger"-->
                    <!--                  size="sm"-->
                    <!--                  class="ml-75 h-25"-->
                    <!--                  md="2"-->
                    <!--                  @click="deleteImg('product_image2')"-->
                    <!--              >-->
                    <!--                <feather-icon icon="TrashIcon"></feather-icon>-->
                    <!--              </b-button>-->
                  </b-col>
                </b-col>
                <b-col md="2"></b-col>
              </b-row>

              <b-col md="6" id="Product image 1 upload">
                <b-form-group
                    label="Theme Thumbnail "
                    label-for="fh-Product1"
                >
                  <b-form-file
                      id="fh-Product1"
                      accept=".jpg, .png, .gif"
                      placeholder="Choose a file..."
                      drop-placeholder="Drop file here..."
                      v-model="product_image1"
                      name="jgj76"
                      @change="previewProductFile1($event)"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                    label="Category"
                    label-for="fh-type"
                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Category"
                  >
                    <v-select
                        id="fh-type"
                        v-model=" modify.category"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="knowledgeBase_category"
                        :reduce="title=>title.id"
                        label="title"

                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                    label="Short Info"
                    label-for="fh-short_info"
                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Short Info"
                  >
                    <b-input-group class="input-group-merge">

                      <b-input-group-prepend is-text>
                        <feather-icon icon="MailIcon"/>
                      </b-input-group-prepend>

                      <b-form-textarea
                          id="fh-short_info"
                          placeholder="Enter a short description"
                          v-model="modify.short_info"
                      />


                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                    label="Content"
                    label-for="fh-content"
                >
                  <quill-editor
                      v-model="modify.info"
                  />
                </b-form-group>
              </b-col>



            </b-row>
          </b-form>
        </validation-observer>
        <div v-else>
          <b-row class="justify-content-center">
            <span>You have <span class="text-success text-bold">SUCCESSFULLY</span> added a module.</span>
          </b-row>
          <b-row  class="justify-content-center">
            <span>To <span class="text-success text-bold"> Add another </span> click <span
                class="text-primary text-bold">Add Another </span> Button.</span>
          </b-row>
          <b-row  class="justify-content-center mt-75">
            <b-button variant="primary" @click="addAnother">Add Another</b-button>
          </b-row>
        </div>
      </tab-content>


    </form-wizard>


  </div>
</template>

<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor'
import {
  BRow,
  BFormFile,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
  BFormRating,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BFormValidFeedback,
  BImg, BContainer
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {mapState} from "vuex";
import knowledgeBaseCategory from "@/views/pages/Knowledge-base/KnowledgeBaseCategory.vue";

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BForm,
    BFormTextarea,
    BButton,
    BFormRating,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,
    BImg, BContainer,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  watch: {},

  computed: {


    ...mapState('knowledgeBaseCategory', ['knowledgeBase_category', "knowledgeBase_categoryLoading", "knowledgeBase_categoryError"]),
  },
  data() {
    return {


      modify: {
        id : "",
        title : "",
        video_link : "",
        info : "",
        short_info : "",
        category : "",
        image : "",

      },

      confirm_password: "",


      moduleAdded: false,
      finished: false,

      modify_id: null,
      business_slug: null,

      product_image: "http://via.placeholder.com/1920x1080",
      product_image1: "http://via.placeholder.com/1920x1080",
      product_image_id1: undefined,
    }
  },
  created() {
    console.log(this.$route.params.id)
    this.$store.dispatch('knowledgeBaseCategory/fetchknowledgeBaseCategory')
    this.$store.dispatch('knowledgeBase/fetchKnowledgeBaseBySlug', this.$route.params.id).then(result=>{
      console.log(result)
      this.modify.id=result.id
      this.modify.category=result.category.id
      this.modify.title=result.title
      this.modify.video_link=result.video_link
      this.modify.short_info=result.short_info
      this.modify.info=result.info
      // this.product_image1="https://mother.nextcart.shop/"+result.image
      this.product_image="https://mother.nextcart.shop/"+result.image
      console.log(result)
    })


  },
  methods: {

    previewProductFile1(evt) {
      const file = evt.target.files[0];
      this.product_image1 = file
      if (file) {
        this.imageRead(file).then(result => {
          this.product_image_id1 = result
          this.product_image = result
        })
      }

    },
    imageRead(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result)
        };

        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    addAnother() {
      this.$router.go();
    },
    editModule() {
      if (this.moduleAdded) {
        return new Promise((resolve, reject) => {
          resolve(true)
        })
      } else {
        return new Promise((resolve, reject) => {
          this.$refs.moduleDetailsRules.validate().then(async success => {
            this.modify.image=this.product_image_id1
            if (success) {
              await this.$store.dispatch('knowledgeBase/updateKnowledgeBase', this.modify).then(result => {
                console.log(result)
                if (result.code != 200) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-end',
                    props: {
                      title: ' Error',
                      icon: 'AlertOctagonIcon',
                      variant: 'danger',
                      text: result.response,
                    },
                  })
                  reject()
                } else {

                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: result.code == 400 ? 'Error' : `Success`,
                      icon: result.code == 400 ? 'ThumbsDownIcon' : 'ThumbsUpIcon',
                      variant: result.code == 400 ? 'danger' : 'success',
                      text: result.response,
                    },
                  })
                  this.moduleAdded = true
                  this.$router.push({name: 'knowledge-base'})

                  resolve(true)
                }
              })
            } else {
              reject()
            }
          })
        })
      }
    },






  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/pages/page-profile.scss';
</style>
